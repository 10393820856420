import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Head from '../components/head'

const NotFound = () => {
  return (
    <Layout>
      <Head title="404" description="Page non trouvée" />
      <h1>Page introuvable</h1>
      <h2>L'Url que vous cherchez n'existe pas ou n'existe plus</h2>
      <p>
        <Link to="/">Retour à l'accueil</Link>
      </p>
    </Layout>
  )
}

export default NotFound
